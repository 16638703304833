Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start


// Method Types.
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";


// Content Types.
exports.contactUsApiContentType = "application/json";


// Endpoints.
exports.getContactUsAPiEndPoint = "contact_us/contacts";
exports.postContactDetailsEndpoint = "bx_block_contact_us/contacts";



// String Constants.
exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"
exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";
exports.namePlaceHolder = "Enter your full name";
exports.fullName = "fullName";
exports.textType = "text";
exports.labelFullName = "Full Name"
exports.emailPlaceHolder = "Enter Email address";
exports.emailName = "email"
exports.emailLabel = "Email address"
exports.emailType = "email"
exports.numberPlaceHolder = "Enter your contact number";
exports.commentsPlaceHolder = "Enter Comments";
exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";
exports.btnExampleTitle = "CLICK ME";
exports.successResponseMsg = "Your message has been sent!";
exports.successSnackbarMsg = "Your message has been sent!";
exports.snackbarFailureMsg = "Something went wrong!"
exports.titleContactUs = "Contact Us";
exports.contactUsSubLabel = "Fill in the form below, and we will get back to you as soon as possible!";
exports.submitButtonText = "Send Message";
exports.contactNumberInputLabel = "Contact Number";
exports.defaultCountry = "US"
exports.nameContactNumber = "contactNumber"
exports.nameSubject = "subject"
exports.subjectLabel = "Subject"
exports.subjectPlaceholder = "What do you want to talk about?"
exports.nameDetails = "details"
exports.detailsLabel = "Details"
exports.detailsPlaceholder = "Any details you would like to share?"
exports.submitType="submit"
exports.nameErrMsg = "Full Name is required!";
exports.emailErrMsg = "Email address is required!";
exports.invalidEmailMsg = "Please enter a valid email address!"
exports.subjectErrMsg = "Subject is required!";
exports.detailsErrMsg = "Details are required!";
exports.contactErrMsg = "Please enter a valid contact number!"

// Customizable Area End