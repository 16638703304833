import React from "react";
// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { Box, Chip, Container, Divider, FormControlLabel, FormGroup, Grid, IconButton, TextField, Typography, styled } from "@material-ui/core";
import { goToPrevPage } from "../../../components/src/Utilities";
import CustomButton from "../../../components/src/CustomButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { archive, family, privacy, wedding } from "./assets";
import CustomCheckbox from "../../../components/src/StyledCheckbox";
import PostCreationController, {Props, configJSON} from "./PostCreationController.web";
// Customizable Area End

export default class PostCreation extends PostCreationController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <React.Fragment>
        <NavigationMenu navigation={this.props.navigation} id={"1"} />
        <Container
          style={webStyles.containerStyle}
          data-test-id="postcreation-container"
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              style={webStyles.IconBtnStyle}
              onClick={() => goToPrevPage()}
            >
              <ArrowBackIcon htmlColor="#fff" />
            </IconButton>
            <Typography style={webStyles.titleStyle}>
              {configJSON.titlePostCreation}
            </Typography>
          </Box>
          <Box
            style={{
              height: "250px",
              borderRadius: "12px",
              marginTop: "40px",
              width: "100%",
              background: "#F5F1EF",
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Add a Title"
              style={{ border: "none" }}
            />
            <Divider
              style={{ backgroundColor: "#CBD5E1", marginBottom: "10px" }}
            />
            <TextField
              variant="outlined"
              placeholder="What do you want to say?"
              style={{ border: "none" }}
            />
          </Box>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              color: "#282627",
              marginTop: "25px",
            }}
          >
            {configJSON.titleSelectCategories}
          </Typography>
          <Box
            style={{
              marginTop: "15px",
              scrollbarWidth: "thin",
              msOverflowStyle: "auto",
              maxWidth: "90%",
              overflowX: "scroll",
              whiteSpace: "nowrap",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Chip
              icon={
                <img src={wedding} height={16} style={{ marginRight: "2px" }} />
              }
              style={{
                marginRight: "30px",
                padding: "4px 12px 4px 10px",
                width: "230px",
                height: "44px",
                borderRadius: "26px",
                fontSize: "16px",
                color: "#fff",
                fontWeight: 700,
                backgroundColor: "#925B5B",
              }}
              label="Wedding"
            />
            <Chip
              icon={<img src={family} />}
              style={{
                marginRight: "30px",
                padding: "4px 12px 4px 10px",
                width: "230px",
                borderColor: "#925B5B",
                height: "44px",
                borderRadius: "26px",
                fontSize: "16px",
                color: "#925B5B",
                fontWeight: 700,
                backgroundColor: "transparent",
              }}
              variant="outlined"
              label="Family"
            />
            <Chip
              icon={<img src={family} />}
              style={{
                marginRight: "30px",
                padding: "4px 12px 4px 10px",
                width: "230px",
                borderColor: "#925B5B",
                height: "44px",
                borderRadius: "26px",
                fontSize: "16px",
                color: "#925B5B",
                fontWeight: 700,
                backgroundColor: "transparent",
              }}
              variant="outlined"
              label="Family"
            />
            <Chip
              icon={<img src={family} />}
              style={{
                marginRight: "30px",
                padding: "4px 12px 4px 10px",
                width: "230px",
                borderColor: "#925B5B",
                height: "44px",
                borderRadius: "26px",
                fontSize: "16px",
                color: "#925B5B",
                fontWeight: 700,
                backgroundColor: "transparent",
              }}
              variant="outlined"
              label="Family"
            />
          </Box>
          <Box style={{ margin: "75px 0px" }}>
            <Box
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "25px",
              }}
            >
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "25px",
                  fontSize: "20px",
                  fontWeight: 700,
                  color: "#0F172A",
                  minWidth: "250px",
                }}
              >
                <img src={privacy} alt="" style={{ marginRight: "20px" }} />{" "}
                Account Privacy
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={this.state.selectedOption === "option1"}
                      onChange={this.handleChange}
                      name="option1"
                    />
                  }
                  label="Public"
                />
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={this.state.selectedOption === "option2"}
                      onChange={this.handleChange}
                      name="option2"
                    />
                  }
                  label="Private"
                />
              </FormGroup>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "25px",
              }}
            >
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "20px",
                  fontWeight: 700,
                  color: "#0F172A",
                  marginRight: "25px",
                  minWidth: "250px",
                }}
              >
                <img src={archive} style={{ marginRight: "20px" }} alt="" />{" "}
                Post Archive
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={this.state.selectedOption === "option1"}
                      onChange={this.handleChange}
                      name="option1"
                    />
                  }
                  label="Temporary default"
                />
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={this.state.selectedOption === "option2"}
                      onChange={this.handleChange}
                      name="option2"
                    />
                  }
                  label="Archive"
                />
              </FormGroup>
            </Box>
          </Box>
          <Divider
            style={{ backgroundColor: "#E2E8F0", marginBottom: "10px" }}
          />
          <Box
            style={{
              margin: "35px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography style={{ color: "#94A3B8", fontSize: "16px" }}>
              Disclaimer : Archived posts will be a fee, temporary will be X
              days, etc.
            </Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "425px",
              }}
            >
              <CustomButton
                variant="outlined"
                color="primary"
                fullWidth
                dataTestId="btn-cancel"
                btnText={"Save Draft"}
                onClick={() => this.DeleteApiCallId}
                style={{
                  width: "196px",
                  fontSize: "20px",
                  height: "56px",
                  fontWeight: 700,
                }}
              />
              <CustomButton
                variant="contained"
                color="primary"
                fullWidth
                dataTestId="verify-btn"
                btnText={"Post"}
                onClick={() => this.DeleteApiCallId}
                style={{
                  width: "196px",
                  fontSize: "20px",
                  height: "56px",
                  fontWeight: 700,
                }}
              />
            </Box>
          </Box>
        </Container>
      </React.Fragment>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles: { [key: string]: React.CSSProperties } = {
  containerStyle: { marginTop: "50px" },

  IconBtnStyle: {
    height: "50px",
    width: "50px",
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    borderRadius: "8px",
    padding: "10px",
    lineHeight: "32px",
    marginRight: "15px",
  },
  titleStyle: {
    fontWeight: "bold",
    fontSize: "24px",
    color: "#282627",
  },
  subLabelStyle: {
    color: "#64748B",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
    margin: "25px 0",
  },
  contactLabelSyle: {
    color: "#334155",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
  },
  btnSubmitStyle: {
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    color: "#fff",
    fontSize: "20px",
    fontWeight: 700,
    display: "inline-block",
    width: "100%",
    maxWidth: "100%",
    minWidth: "100%",
    borderRadius: "8px",
    height: "56px",
  },
};
// Customizable Area End
