import React from "react";
import { Button, styled } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";

interface Props {
  variant: ButtonProps["variant"];
  color: ButtonProps["color"];
  disabled?: boolean;
  btnText: string;
  fullWidth: boolean;
  dataTestId?: string;
  onClick: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  style?: React.CSSProperties;
}

const StyledButton = styled(Button)({
  "&.Mui-disabled": {
    background: "#CBD5E1 !important",
    color: "#64748B !important",
  },

  "&.MuiButton-containedPrimary": {
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    color: "#fff",
    fontSize: "20px",
    fontWeight: 700,
    width: "100%",
    borderRadius: "8px",
    height: "56px",
    textTransform: "capitalize",
  },

  "&.MuiButton-outlinedPrimary": {
    border: "2px solid",
    borderColor: "#925B5B",
    color: "#925B5B",
    fontSize: "20px",
    fontWeight: 700,
    width: "100%",
    borderRadius: "8px",
    height: "56px",
    textTransform: "capitalize",
    background: "transparent",
  },
});

const CustomButton = (props: Props) => {
  const { variant, disabled, btnText, color, fullWidth, dataTestId, onClick, style } =
    props;

  return (
    <StyledButton
      variant={variant}
      disabled={disabled}
      color={color}
      fullWidth={fullWidth}
      data-test-id={dataTestId}
      onClick={onClick}
      style={{...style}}
    >
      {btnText}
    </StyledButton>
  );
};

export default CustomButton;
